var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-button",
        {
          attrs: { type: "border" },
          on: {
            click: function ($event) {
              return _vm.consultarEndossos()
            },
          },
        },
        [_vm._v("\n    Configuração de restituição\n  ")]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "configuracaoRestituicaoDialog",
          attrs: {
            "show-close": true,
            center: "",
            visible: _vm.configuracaoRestituicaoDialog,
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.configuracaoRestituicaoDialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", { staticClass: "boldText" }, [
                    _vm._v("Configuração de restituição"),
                  ]),
                  _c("el-divider", { staticClass: "mt-3 mb-0" }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._l(_vm.endossosDaProposta, function (propostaItem, i) {
            return _c(
              "div",
              { key: i, staticClass: "mr-0 ml-0" },
              [
                _c("div", { staticClass: "configuracaoRestituicaoTable" }, [
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [
                      _vm._v("Nº endosso"),
                    ]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(propostaItem.Endorsement.Number) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [
                      _vm._v("Proposta do endosso"),
                    ]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(_vm._s(propostaItem.Number)),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [_vm._v("Emissão")]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatDate(propostaItem.Endorsement.CreateDate)
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [
                      _vm._v("Início da vigência"),
                    ]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.formatDate(propostaItem.StartDate)) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [
                      _vm._v("Fim da vigência"),
                    ]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.formatDate(propostaItem.EndDate)) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [_vm._v("Tipo")]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(propostaItem.Endorsement.EndorsementType) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [_vm._v("IS")]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(
                        "\n            R$ " +
                          _vm._s(propostaItem.InsuredAmountValue) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "boldText" }, [_vm._v("Prêmio")]),
                    _c("p", { staticClass: "endossoItemTable" }, [
                      _vm._v(
                        "\n            R$ " +
                          _vm._s(propostaItem.InsurancePremium) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "'position": "relative", top: "9px" } },
                    [
                      _c("p", { staticClass: "boldText" }, [
                        _vm._v("\n            Confirmar valor "),
                        _c("i", { staticClass: "asteristicColor" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _c("el-input", {
                        staticClass: "inputRestituitionDialog",
                        attrs: { placeholder: "R$", max: "10" },
                        model: {
                          value: propostaItem.newInsurancePremium,
                          callback: function ($$v) {
                            _vm.$set(propostaItem, "newInsurancePremium", $$v)
                          },
                          expression: "propostaItem.newInsurancePremium",
                        },
                      }),
                      _c("div", [
                        propostaItem.newInsurancePremium >
                        propostaItem.InsurancePremium
                          ? _c("p", { staticClass: "alertValueError" }, [
                              _vm._v(
                                "\n              O Valor deve ser menor que o prêmio\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]),
                i < _vm.endossosDaProposta.length - 1
                  ? _c("el-divider")
                  : _vm._e(),
              ],
              1
            )
          }),
          _c("el-divider", { staticClass: "mb-4" }),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "end" } },
            [
              _c(
                "button",
                {
                  staticClass: "configurarRestituicaoButtonCancel",
                  on: {
                    click: function ($event) {
                      _vm.configuracaoRestituicaoDialog = false
                    },
                  },
                },
                [_vm._v("\n        Cancelar\n      ")]
              ),
              _c(
                "button",
                {
                  class: _vm.valueRestituitionError
                    ? "configurarRestituicaoButtonSaveDisabled"
                    : "configurarRestituicaoButtonSave",
                  attrs: { disabled: _vm.valueRestituitionError },
                  on: {
                    click: function ($event) {
                      return _vm.createRestituitionEndorsement(
                        _vm.endossosDaProposta
                      )
                    },
                  },
                },
                [_vm._v("\n        Salvar alterações\n      ")]
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }